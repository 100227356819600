
<template>
  <v-container fluid>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-card>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>Açoes</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-dialog
                    v-model="dialogAlterarStatusLote"
                    scrollable
                    width="500"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="primary"
                        class="mr-5"
                        :disabled="selected.length == 0"
                        v-on="on"
                      >
                        Alterar estado
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>Alteração do Estado do Pedido (em lote)</v-card-title>
                      <v-card-text>
                        <v-select
                          v-model="lote.novoStatus"
                          label="Novo estado"
                          :items="statuses"
                          item-text="display"
                          item-value="id"
                        />
                        <v-textarea
                          v-model="lote.observacao"
                          label="Observação"
                          name="observacao"
                          type="text"
                        />
                      </v-card-text>
                      <v-divider />
                      <v-card-actions>
                        <v-btn
                          color="red darken-1"
                          text
                          @click="dialogAlterarStatusLote = false"
                        >
                          Cancelar
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="alterarEstado({
                            StatusId: lote.novoStatus,
                            Ids: selected,
                            Observacao: lote.observacao
                          })"
                        >
                          Salvar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-btn
                    color="secondary"
                    :disabled="true"
                  >
                    Imprimir
                  </v-btn>
                  <v-btn
                    color="success"
                    dark
                    class="ml-4"
                    @click="download('excel')"
                  >
                    Lista - Excel
                  </v-btn>
                  <v-btn
                    color="success"
                    class="ml-4"
                    :disabled="true"
                    @click="download('lista')"
                  >
                    Lista - PDF
                  </v-btn>
                  <v-btn
                    color="success"
                    class="ml-4"
                    :disabled="true"
                    @click="download('card')"
                  >
                    Detalhes - PDF
                  </v-btn>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Filtro</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-col cols="12">
                  <v-form @submit.prevent="load">
                    <v-row class="p-2">
                      <v-col
                        cols="4"
                        sm="4"
                        md="4"
                      >
                        <v-text-field
                          v-model="filter.Nome"
                          label="Nome"
                          required
                        />
                      </v-col>
                      <v-col
                        cols="4"
                        sm="4"
                        md="4"
                      >
                        <v-text-field
                          v-model="filter.PedidoId"
                          label="ID do Pedido"
                          required
                        />
                      </v-col>
                      <v-col
                        cols="4"
                        sm="4"
                        md="4"
                      >
                        <v-text-field
                          v-model="filter.CodigoStyllus"
                          label="Código Styllus"
                          required
                        />
                      </v-col>
                      <v-col
                        cols="4"
                        sm="4"
                        md="4"
                      >
                        <v-select
                          v-model="filter.StatusId"
                          label="Status"
                          :items="statuses"
                          item-text="display"
                          item-value="id"
                        />
                      </v-col>
                      <v-col
                        cols="4"
                        sm="4"
                        md="4"
                      >
                        <v-text-field
                          v-model="filter.ValorMinimo"
                          label="Valor Mínimo"
                          type="number"
                          required
                        />
                      </v-col>
                      <v-col
                        cols="4"
                        sm="4"
                        md="4"
                      >
                        <v-text-field
                          v-model="filter.ValorMaximo"
                          label="Valor Máximo"
                          type="number"
                          required
                        />
                      </v-col>
                      <v-col
                        cols="4"
                        sm="4"
                        md="4"
                      >
                        <v-select
                          v-model="filter.TipoPagamentoId"
                          label="Forma de Pagamento"
                          :items="pagamentos"
                          item-text="nome"
                          item-value="id"
                        />
                      </v-col>
                      <v-col
                        cols="4"
                        sm="4"
                        md="4"
                      >
                        <v-menu
                          ref="datainicioMenu"
                          v-model="datainicioMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="datainicioFormatada"
                              v-mask="['##/##/####']"
                              label="Data Início"
                              prepend-icon="mdi-calendar"
                              :readonly="true"
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-model="filter.DataInicio"
                            no-title
                            @input="datainicioMenu = false"
                          />
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="4"
                        sm="4"
                        md="4"
                      >
                        <v-menu
                          ref="datafimMenu"
                          v-model="datafimMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="datafimFormatada"
                              v-mask="['##/##/####']"
                              label="Data Fim"
                              prepend-icon="mdi-calendar"
                              :readonly="true"
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-model="filter.DataFim"
                            no-title
                            @input="datafimMenu = false"
                          />
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="4"
                        sm="4"
                        md="4"
                      >
                        <v-select
                          v-model="filter.StatusPagamento"
                          label="Status do Pagamento"
                          :items="statusPagamento"
                          item-text="nome"
                          item-value="id"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-btn
                          color="primary"
                          style="margin-top: 16px;"
                          @click="load"
                        >
                          Buscar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
        <v-card class="mt-4">
          <v-data-table
            :headers="headers"
            :items="pedidos"
            :options.sync="options"
            :server-items-length="total"
            :footer-props="footer_props"
            class="elevation-1"
            show-select
            :single-select="false"
          >
            <template v-slot:header.data-table-select>
              <v-checkbox
                v-model="selectedAll"
                label="Todos"
                @change="toogleAll()"
              />
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                >
                  <td>
                    <v-checkbox
                      v-model="selected"
                      :value="item.id"
                      :disabled="item.status && item.status.nomeStatus == 'CANCELADO'"
                    />
                  </td>
                  <td>
                    <router-link :to="`/pages/pedidos/detail/${item.id}`">
                      {{ item.id }}
                    </router-link>
                  </td>
                  <td>
                    {{ item.cliente.nome.substring(0, 20).toUpperCase() }}...
                  </td>
                  <td>
                    {{ item.cliente.codigoStyllus }}
                  </td>
                  <td>
                    {{ item.cliente.tipo }}
                  </td>
                  <td>{{ item.totalLiquido.toFixed(2) }}</td>
                  <td>{{ timestamp(item.dataPedido) }}</td>
                  <td>{{ item.status ? item.status.nomeStatus : 'Status desconhecido' }}</td>
                  <td>{{ item.tipoPagamento.nome.substring(0, 7).toUpperCase() }}</td>
                  <td>{{ item.statusPgto }}</td>
                  <td>
                    <!-- <v-btn
                      small
                      color="primary"
                      :disabled="item.status && item.status.nomeStatus == 'CANCELADO'"
                      @click="alterar(item)"
                    >
                      alterar estado
                    </v-btn> -->
                    <v-btn
                      v-if="item.statusPagamentoTexto != 'Pago'"
                      small
                      class="ml-2"
                      color="orange darken-1"
                      @click="novoBoletoModal(item)"
                    >
                      novo boleto
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="novoBoleto"
      width="520"
    >
      <v-card>
        <v-card-title class="text-h5">
          Atualizar vencimento para um novo boleto?
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="vencimento"
            label="Vencimento"
            name="Vencimento"
            type="date"
          />
          <v-textarea
            v-model="obs"
            label="Observações"
          />
        </v-card-text>

        <v-card-actions>
          <div class="flex-grow-1" />

          <v-btn
            outlined
            class="ml-4"
            large
            color="error"
            dark
            @click="novoBoleto = false"
          >
            Espere, vou verificar
          </v-btn>

          <v-btn
            outlined
            class="ml-4"
            large
            color="success"
            dark
            :disabled="!vencimento"
            @click="confirmNovoBoleto()"
          >
            Sim, prossiga
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Você tem certeza?
        </v-card-title>

        <v-card-text>
          O pedido será deletada. Deseja prosseguir?
        </v-card-text>

        <v-card-actions>
          <div class="flex-grow-1" />

          <v-btn
            outlined
            class="ml-4"
            large
            color="error"
            dark
            @click="dialog = false"
          >
            Espere, vou verificar
          </v-btn>

          <v-btn
            outlined
            class="ml-4"
            large
            color="success"
            dark
            @click="confirmDelete()"
          >
            Sim, prossiga
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogAlterarStatus"
      scrollable
      width="500"
    >
      <v-card>
        <v-card-title>Alteração do Estado do Pedido</v-card-title>
        <v-card-text>
          <v-select
            v-model="itemselected.novoStatus"
            label="Novo estado"
            :items="statuses"
            item-text="display"
            item-value="id"
          />
          <v-textarea
            v-model="itemselected.observacao"
            label="Observação"
            name="observacao"
            type="text"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            @click="dialogAlterarStatus = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="alterarEstado({
              StatusId: itemselected.novoStatus,
              PedidoId: itemselected.id,
              Observacao: itemselected.observacao
            })"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import mime from 'mime-types'

  export default {
    name: 'PedidoList',
    props: {
      tipo: {
        type: Number,
        default: 1,
      },
    },
    data: () => ({
      overlay: true,
      dialogAlterarStatus: false,
      dialogAlterarStatusLote: false,
      datainicioMenu: false,
      datafimMenu: false,
      novoBoleto: false,
      vencimento: moment().add(1, 'days').format('YYYY-MM-DD'),
      obs: null,
      itemselected: {},
      lote: {},
      pedidos: [],
      statuses: [],
      pagamentos: [
        { nome: 'A Prazo', id: 1 },
        { nome: 'A Vista', id: 2 },
        { nome: 'Pagamento realizado na loja', id: 3 },
        { nome: 'Pagamento realizado ao Promotor', id: 4 },
        { nome: 'Todos', id: null },
      ],
      headers: [
        { text: 'ID', sortable: false },
        { text: 'Revendedora', sortable: false },
        { text: 'ID Styllus', sortable: false },
        { text: 'Tipo Rev.', sortable: false },
        { text: 'Valor', sortable: false },
        { text: 'Data', sortable: false },
        { text: 'Status', sortable: false },
        { text: 'Tp. Pgto', sortable: false },
        { text: 'Status Pgto', sortable: false },
        { text: 'Ações', sortable: false },
      ],
      footer_props: {
        'items-per-page-options': [20, 50, 100, 200],
      },
      selected: [],
      selectedAll: false,
      total: 0,
      dialog: false,
      options: {
        sortBy: [],
        sortDesc: [],
      },
      filter: {
        PerPage: 25,
        Page: 1,
        DataInicio: null,
        DataFim: null,
        SortAscending: true,
      },
      statusPagamento: [
        {
          nome: 'Cobrança Criada',
          id: 0,
        },
        {
          nome: 'Estornado',
          id: 1,
        },
        {
          nome: 'Recusado',
          id: 2,
        },
        {
          nome: 'Autorizado',
          id: 3,
        },
        {
          nome: 'Aguardando Pagamento',
          id: 4,
        },
        {
          nome: 'Pago',
          id: 5,
        },
        {
          nome: 'Devolução Pendente',
          id: 6,
        },
        {
          nome: 'Devolvido',
          id: 7,
        },
        {
          nome: 'Processando',
          id: 8,
        },
        {
          nome: 'Revisão pendente',
          id: 9,
        },
        {
          nome: 'Analisando',
          id: 0,
        },
        {
          nome: 'Cobrança Cancelada',
          id: 1,
        },
        {
          nome: 'Pagamento Cancelado',
          id: 2,
        },
        {
          nome: 'Transação não autorizada',
          id: 99,
        },
      ],
    }),
    computed: {
      datainicioFormatada () {
        return this.parseDate(this.filter.DataInicio)
      },
      datafimFormatada () {
        return this.parseDate(this.filter.DataFim)
      },
    },
    watch: {
      options: {
        handler () {
          this.load()
        },
        deep: true,
      },
    },
    methods: {
      novoBoletoModal (item) {
        this.selected = item
        this.novoBoleto = true
      },
      toogleAll () {
        if (this.selectedAll) {
          this.selected = [...this.pedidos.map(p => p.id)]
        } else {
          this.selected = []
        }
      },
      timestamp (datestring) {
        return moment(datestring).format('DD/MM/YYYY')
      },
      parseDate (dateString) {
        if (!dateString) {
          return ''
        }
        const [year, month, day] = dateString.split('-')
        return `${day}/${month}/${year}`
      },
      load () {
        this.overlay = true
        this.filter.PerPage = this.options.itemsPerPage
        this.filter.Page = this.options.page
        if (!this.filter.Tipo) {
          this.filter.Tipo = (this.$route.params.tipo === 'captura') ? 2 : 1
        }
        const query = JSON.stringify(this.filter)
        this.$http.get('/pedido', { params: { query } })
          .then(resp => {
            this.pedidos = resp.data.rows
            this.total = resp.data.total
            this.overlay = false
          })
        this.$http.get('/pedido/status')
          .then(resp => {
            this.statuses = resp.data
          })
      },
      alterar (pedido) {
        this.itemselected = { ...pedido }
        this.dialogAlterarStatus = true
      },
      alterarEstado (payload) {
        this.dialogAlterarStatus = false
        this.dialogAlterarStatusLote = false
        this.$http.post('pedido/status', payload)
          .then(resp => {
            this.$toast.success('Estado do pedido alterado com sucesso :D')
            this.selected = []
            this.load()
          })
          .catch((err) => {
            try {
              const msg = err.response.data.message
              this.$toast.error(msg)
            } catch (error) {
              this.$toast.error('Falha ao realizar operação')
            }
          })
      },
      destroy (item) {
        this.itemselected = item
        this.dialog = true
      },
      confirmDelete () {
        this.$http.delete(`/pedido/${this.itemselected.id}`)
          .then(resp => {
            this.$toast.success('Pedido removido com sucesso :D')
            this.dialog = false
            this.load()
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      confirmNovoBoleto () {
        this.$http.post(`pedido/${this.selected.id}/boleto`, {
          vencimento: this.vencimento,
          id: this.selected.id,
          observacao: this.obs,
        })
          .then(() => {
            this.$toast.success('Novo boleto gerado!')
            this.vencimento = moment().add(1, 'days').format('YYYY-MM-DD')
            this.obs = null
            this.novoBoleto = false
            this.load()
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      forceFileDownload (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        const filename = Math.round((new Date()).getTime() / 1000)
        link.setAttribute('download', `${filename}.${mime.extension(response.headers['content-type'])}`)
        document.body.appendChild(link)
        link.click()
      },
      download (tipo) {
        this.filter.TipoRelatorio = tipo
        this.filter.TotalPedidos = this.total
        const query = JSON.stringify(this.filter)
        this.$http.get('relatorio/pedido', { params: { query }, responseType: 'arraybuffer' })
          .then(response => {
            this.forceFileDownload(response)
          })
      },
    },
    beforeRouteUpdate (to, from, next) {
      this.filter.Tipo = (to.params.tipo === 'captura') ? 2 : 1
      this.filter.Page = 1
      this.load()
      next()
    },
  }
</script>

<style lang="scss" scoped>
.v-menu__content.menuable__content__active {
  max-width: 290px !important;
  min-width: 290px !important;
  width: 290px !important;
}
</style>
